.nav-tabs {
	border-bottom: none;
	
	.nav-link {
		text-transform:   uppercase;
		background-color: $black;
		@media(min-width: 576px) {
			background-color: lighten($primary, 15);
			color:            $black;
			border:           none;
			border-radius:    0.25rem;
			box-shadow:       inset 0 0 0 1px $black;
			min-height:       60px;
			font-size:        0.8rem;
		}
		@media(min-width: 1200px) {
			font-size:      1rem;
			padding-top:    1rem;
			padding-bottom: 1rem;
		}
	}
	
	.nav-item.show .nav-link,
	.nav-link.active {
		background-color: $black;
		@media(min-width: 576px) {
			background-color: $primary;
			color:            $black;
			border:           none;
		}
	}
	
	.dropdown-menu {
		background-color: $black;
		box-shadow:       0 0 2px 2px $primary;
		border:           none;
		width:            100%;
		
		.dropdown-item {
			color: $white;
			
			&:hover, &:focus {
				background-color: $grey;
				color:            $primary;
			}
		}
	}
}

.tab-content {
	margin-top: 0.5rem;
	@media(min-width: 1200px) {
		margin-top: 1rem;
	}
}