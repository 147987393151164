.site-footer {
	background-color: $dark;
	padding-top:      2rem;
	padding-bottom:   1.5rem;
	@media(min-width: 992px) {
		padding-top:    4rem;
		padding-bottom: 3rem;
	}
	
	.main-logo a {
		font-size: 2rem;
	}
	
	.footer-logo {
		img {
			height:    auto;
			width:     100%;
			max-width: 310px;
		}
	}
	
	.copyright-text {
		margin-top:    0.25rem;
		margin-bottom: 0;
		font-size:     0.66rem;
		font-weight:   300;
		
		a {
			color: darken($white, 10);
			
			&:hover {
				color: $white;
			}
		}
	}
	
	.payment-options {
		@extend .list-unstyled;
		line-height:   1;
		font-size:     0.75rem;
		font-weight:   300;
		margin-bottom: 0;
		margin-top:    1rem;
		color:         $light;
		@media(min-width: 992px) {
			margin-top: 0;
		}
		/*a {
			color: $light;
			
			&:hover {
				color: $white;
			}
		}*/
		li {
			display: inline-block;
			margin:  0 0.5rem 1rem 0.5rem;
			
			&:last-child {
				margin-right:  0;
				margin-bottom: 0;
			}
			
			@media(min-width: 992px) {
				margin-bottom: 0;
			}
		}
	}
	
	.payment-icons {
		width:  auto;
		height: 18px;
	}
	
	.licence-numbers {
		color:       $grey;
		font-size:   0.8rem;
		font-weight: 300;
		
		p {
			margin-bottom: 0;
		}
	}
	
	.social-links {
		color:         $grey;
		margin-bottom: 1rem;
		@media(min-width: 992px) {
			margin-bottom: 0;
		}
		
		h5 {
			vertical-align: middle;
		}
		
		ul {
			@extend .list-unstyled;
			display:       inline-block;
			margin-bottom: 0;
			
			li {
				display:       inline-block;
				margin:        0.25rem 0;
				padding-left:  0.25rem;
				padding-right: 0.25rem;
				
				a {
					i {
						display:       inline-block;
						border:        1px solid $primary;
						border-radius: 0.5rem;
						
						&:after {
							mask-position: center;
							mask-size:     1.5rem 1.5rem;
							height:        2.25rem;
							width:         2.25rem;
							padding:       0.5rem 0.75rem;
							transition:    all 0.5s ease;
						}
					}
					
					&:hover {
						i {
							background-color: $primary;
							box-shadow:       0 0 0 1px $black inset;
							
							&:after {
								background-color: $dark;
							}
						}
					}
				}
			}
		}
	}
}