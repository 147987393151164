.what {
	@extend .section-paddings;
	
	&-item {
		position:      relative;
		text-align:    center;
		margin-top:    2rem;
		
		img {
			height:     150px;
			width:      auto;
			transition: 1s ease;
			
			@media(min-width: 1200px) {
				height: 185px;
			}
		}
		
		a {
			display:          block;
			background-color: $dark;
			border-radius:    0.5rem;
			margin-top:       -4rem;
			text-transform:   uppercase;
			line-height:      1.35;
			padding:          5rem 1rem 1rem 1rem;
			font-size:        1.25rem;
			@media(min-width: 768px) {
				padding:          5rem 0.5rem 1rem 0.5rem;
			}
			@media(min-width: 1200px) {
				margin-top:       -5rem;
				padding:   7rem 1.5rem 2rem 1.5rem;
				font-size: 1.35rem;
			}
			@media(min-width: 1400px) {
				padding:   7rem 1rem 2rem 1rem;
				font-size: 1.75rem;
			}
			
			&:hover {
				color: $primary;
			}
		}
		
		&:hover {
			img {
				transform: scale(1.1);
			}
		}
	}
}