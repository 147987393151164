#navbarNavOffcanvas {
	font-size:   1.375rem;
	border-left: 2px solid $primary;
	
	.offcanvas-body {
		padding-left:   2rem;
		padding-right:  2rem;
		text-transform: uppercase;
		
		.nav-link {
			color: $light;
			
			&:hover {
				color: $primary;
			}
		}
		
		.dropdown-menu {
			background-color: unset;
			border:           none;
			border-radius:    0;
			margin-top:       0;
			
			.dropdown-item {
				color: $light;
				font-weight: inherit;
				
				&:hover, &:focus, &:active {
					background-color: unset;
					color:            $primary;
				}
				
				&:before {
					display:          inline-block;
					content:          " ";
					height:           0.75rem;
					width:            0.75rem;
					margin-right:     0.125rem;
					mask-image:       url("#{$img-path}/chevron-double-right-light.svg");
					mask-repeat:      no-repeat;
					mask-size:        contain;
					mask-position:    top left;
					background-color: $primary;
					vertical-align:   middle;
				}
			}
			
			.dropdown-menu {
				margin-left: 1rem;
				.dropdown-item {
					font-size: 0.9rem;
					&:before {
						height: 0.85rem;
						width:  0.5rem;
					}
				}
			}
			
		}
		
		.active .nav-link,
		.current-menu-item .dropdown-item {
			color: $white;
		}
	}
}

#main-nav {
	.offcanvas-backdrop {
		&.show {
			opacity: 0.75;
		}
	}
}