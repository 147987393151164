.home-services {
	@extend .section-paddings;
	
	&-item {
		background-color: $dark;
		border-radius:    0.5rem;
		padding:          1rem;
		margin:           1rem auto;
		/*@media(min-width: 576px) {
			margin-left:  unset;
			margin-right: unset;
		}*/
		@media(min-width: 1400px) {
			padding:       2rem;
			margin-top:    2rem;
			margin-bottom: 2rem;
		}
		
		&:last-child {
			margin-bottom: 0;
		}
		
		&-image img {
			width:      auto;
			max-height: 150px;
			@media(min-width: 1200px) {
				max-height: 250px;
			}
		}
		
		ul {
			margin-bottom: 0;
		}
	}
}