.form-control {
	box-shadow:    0 0 0 $white inset;
	border-radius: 0.5rem;
	color:         $black;
	font-size:     1rem;
	margin:        0.75rem auto;
	
	&:focus-visible {
		text-shadow: none;
		color:       $black;
	}
}

.field-hidden1 {
	display:    none !important;
	visibility: hidden !important;
}

form {
	h2, h3 {
		@extend .h2;
		@extend .text-uppercase;
		@extend .text-primary;
	}
	
	.form-label {
		@extend .h4;
		@extend .subtitle;
		@extend .text-uppercase;
		@extend .text-primary;
		@extend .mt-3;
		margin-bottom: 0;
	}
	
	textarea {
		@extend .mb-3;
	}
	
	.btn {
		@extend .mt-3;
	}
}

.mauticform-errormsg {
	display: none; //hide Mautic Error message as we're now using Bootstrap5 styled error message
}

.form-check-input[type="radio"] {
	&:nth-of-type(2) {
		margin-left: 1rem;
	}
}

select {
	&.form-control {
		@extend .form-select;
	}
}

.is-invalid {
	background-image:    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") !important;
	background-repeat:   no-repeat;
	border-color:        #FB3048;
	background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem) !important;
	background-size:     calc(.75em + .375rem) calc(.75em + .375rem) !important;
}

.is-valid {
	background-image:    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyMyOGE3NDUnIGQ9J00yLjMgNi43M0wuNiA0LjUzYy0uNC0xLjA0LjQ2LTEuNCAxLjEtLjhsMS4xIDEuNCAzLjQtMy44Yy42LS42MyAxLjYtLjI3IDEuMi43bC00IDQuNmMtLjQzLjUtLjguNC0xLjEuMXonLz48L3N2Zz4=") !important;
	background-repeat:   no-repeat;
	border-color:        #28A745;
	background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem) !important;
	background-size:     calc(.75em + .375rem) calc(.75em + .375rem) !important;
}

[data-bs-toggle="hide"] {
	display: none !important;
}

[data-bs-toggle="show"] {
	display: block;
}
