.herohome {
	position:         relative;
	background-color: $black;
	z-index:          1;
	overflow:         hidden;
	
	&-bannerimg {
	}
	
	&-textwrapper {
		@extend .section-paddings;
		z-index:        3;
		
		h1 {
			@extend .mainheader;
			
			span {
				display:        block;
				color:          $primary;
				margin:         0;
				text-transform: uppercase;
			}
		}
		
		p {
			margin:      0;
			line-height: 1.5;
			@media(min-width: 768px) {
				font-size: 1rem;
			}
			@media(min-width: 992px) {
				font-size: 1.15rem;
			}
			@media(min-width: 1200px) {
				font-size: 1.25rem;
			}
		}
		
		.btn {
			margin-top:     1rem;
			text-transform: uppercase;
			@media(min-width: 992px) {
				margin-top: 2rem;
			}
		}
	}
	
	&-vanimage {
		@media(min-width: 768px) {
			position: absolute;
			top:      15%;
			right:    0;
			width:    50%;
		}
	}
	
	&-manimage {
		display: none;
		@media(min-width: 768px) {
			position:  absolute;
			bottom:    0;
			right:     10%;
			height:    85%;
			width:     auto;
			max-width: none;
			display:   block;
		}
	}
}