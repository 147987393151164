.fa-2x:after {
	transform: scale(1.1);
}

.fa:after,
.fab:after,
.fal:after,
.fad:after {
	display:          inline-block;
	height:           2rem;
	width:            2rem;
	content:          " ";
	mask-repeat:      no-repeat;
	mask-size:        contain;
	mask-position:    top left;
	background-color: $primary;
	vertical-align:   middle;
}

.fa-facebook-f:after,
.fa-facebook:after {
	mask-image: url("#{$img-path}/facebook-icon.svg");
}

.fa-instagram:after {
	mask-image: url("#{$img-path}/instagram-icon.svg");
}

.fa-linkedin-in:after,
.fa-linkedin:after {
	mask-image: url("#{$img-path}/linkedin-icon.svg");
}

.fa-tiktok:after {
	mask-image: url("#{$img-path}/tiktok-icon.svg");
}

.fa-twitter:after {
	mask-image: url("#{$img-path}/twitter-icon.svg");
}

.fa-youtube:after {
	mask-image: url("#{$img-path}/youtube-icon.svg");
}

.fa-map-marker:after,
.fa-map-marker-alt:after {
	mask-image: url("#{$img-path}/map-marker-alt-duotone.svg");
}

.fa-chevron-double-right:after {
	mask-image: url("#{$img-path}/chevron-double-right-light.svg");
}

.fa-chevron-down-solid:after {
	mask-image: url("#{$img-path}/chevron-down-solid.svg");
}