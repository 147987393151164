.where {
	@extend .section-paddings;
	
	&-texts {
	}
	
	ul {
		@extend .list-unstyled;
		width: 300px;
		@media(min-width: 1400px) {
			width: 400px;
		}
		
		li {
			display:   inline-block;
			font-size: 1.1rem;
			width:     145px;
			@media(min-width: 1400px) {
				width: 195px;
			}
		}
	}
}