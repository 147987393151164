h1, h2, h3, h4, h5, h6 {
	font-family: $secondary-font;
}

a {
	text-decoration:  none;
	background-color: transparent;
	outline:          none;
}

strong {
	font-weight: 700;
}

p {
}

.lead {
	line-height: 1.4;
}

.custom-ul,
.entry-content ul {
	list-style:   none;
	padding-left: 0;
	
	li {
		&:before {
			display:          inline-block;
			height:           0.8rem;
			width:            0.8rem;
			content:          " ";
			mask-repeat:      no-repeat;
			mask-size:        contain;
			mask-position:    top left;
			background-color: $primary;
			margin-right:     1rem;
			vertical-align:   revert;
			mask-image:       url("#{$img-path}/chevron-double-right-light.svg");
		}
	}
}
.entry-content dl {
	list-style:   none;
	padding-left: 0;
	
	dt {
		&:before {
			display:          inline-block;
			height:           0.8rem;
			width:            0.8rem;
			content:          " ";
			mask-repeat:      no-repeat;
			mask-size:        contain;
			mask-position:    top left;
			background-color: $primary;
			margin-right:     1rem;
			vertical-align:   revert;
			mask-image:       url("#{$img-path}/chevron-double-right-light.svg");
		}
	}
	
	dd {
		padding-left: 1rem;
	}
}

.mainheader {
	line-height: 0.8;
	margin:      0;
	font-size:   2.75rem;
	@media(min-width: 768px) {
		//font-size: 2.5rem;
	}
	@media(min-width: 992px) {
		font-size: 4rem;
	}
	@media(min-width: 1200px) {
		//font-size: 4rem;
	}
	@media(min-width: 1600px) {
		font-size: 5rem;
	}
}

.title {
	line-height: 1;
	margin:      0;
	font-size:   2rem;
	@media(min-width: 1200px) {
		font-size: 2.75rem;
	}
	@media(min-width: 1400px) {
		font-size: 3.125rem;
	}
}

.subtitle {
	line-height: 1.5;
	font-size:   1.125rem;
	@media(min-width: 1200px) {
		font-size: 1.25rem;
	}
}

.text-primary {
	color: $primary !important;
}