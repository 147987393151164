/*
* Enable and replace font names/paths as required
 */
@font-face {
	font-family:   'Roboto Condensed Bold';
	font-weight:   normal;
	font-style:    normal;
	font-display:  swap;
	unicode-range: U+000-5FF;
	src:           local('Roboto Condensed Bold'), local('Roboto-Condensed-Bold'),
	               url('#{$font-path}/roboto_condensed-bold.woff2') format('woff2');
}
@font-face {
	font-family:   'Rubik';
	font-weight:   300;
	font-style:    normal;
	font-display:  swap;
	unicode-range: U+000-5FF;
	src:           local('Rubik Light'), local('Rubik-Light'),
	               url('#{$font-path}/rubik-light.woff2') format('woff2');
}

@font-face {
	font-family:   'Rubik';
	font-weight:   500;
	font-style:    normal;
	font-display:  swap;
	unicode-range: U+000-5FF;
	src:           local('Rubik Medium'), local('Rubik-Medium'),
	               url('#{$font-path}/rubik-medium.woff2') format('woff2');
}

@font-face {
	font-family:   'Rubik';
	font-weight:   700;
	font-style:    normal;
	font-display:  swap;
	unicode-range: U+000-5FF;
	src:           local('Rubik Bold'), local('Rubik-Bold'),
	               url('#{$font-path}/rubik-bold.woff2') format('woff2');
}

@font-face {
	font-family:   'Rubik';
	font-weight:   900;
	font-style:    normal;
	font-display:  swap;
	unicode-range: U+000-5FF;
	src:           local('Rubik Black'), local('Rubik-Black'),
	               url('#{$font-path}/rubik-black.woff2') format('woff2');
}