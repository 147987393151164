.hero-general {
	position:         relative;
	background-color: $black;
	z-index:          1;
	overflow:         hidden;
	
	&-textwrapper {
		@extend .section-paddings;
		z-index: 3;
	}
	
	&-imgwrapper {
		@extend .section-paddings;
		
		img {
			max-height: 200px;
			width:      auto;
			height:     auto;
			@media(min-width: 768px) {
				max-height: 250px;
			}
			@media(min-width: 1400px) {
				max-height: 275px;
			}
		}
	}
	
	&-slanted {
		position: absolute;
		z-index:  -1;
		height:   100%;
		width:    auto;
		top:      0;
		left:     0;
		right:    0;
		margin:   0 auto;
	}
}