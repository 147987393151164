//overwriting the understrap class
//default paddings
.section-paddings {
	padding-top:    2rem;
	padding-bottom: 2rem;
	@media(min-width: 768px) {
		padding-top:    3rem;
		padding-bottom: 3rem;
	}
	@media(min-width: 1400px) {
		padding-top:    4rem;
		padding-bottom: 4rem;
	}
}
.wrapper {
	@extend .section-paddings;
}

//essentials css setup
*:focus {
	outline: none;
}

body {
	font-family:      $primary-font;
	font-weight:      300;
	font-size:        1rem;
	line-height:      1.5;
	color:            $white;
	background-color: $black;
}

/*@media (prefers-color-scheme: dark) {
	body {
		background-color: $black;
		color:            $white;
	}
}*/

//essential utilities
.bg-image {
	position:        absolute;
	height:          100%;
	width:           100%;
	top:             0;
	left:            0;
	object-fit:      cover;
	object-position: center;
	z-index:         -1;
}

.main-logo {
	font-family: $secondary-font;
	
	a {
		font-size:   1.5rem;
		color:       $primary;
		line-height: 1;
		display:     inline-block;
		transform:   rotate(180deg) scaleY(1.25);
		
		span {
			color: $white;
		}
		
		&:hover {
			color: $primary;
		}
		
		@media(min-width: 768px) {
			font-size: 2rem;
		}
		@media(min-width: 992px) {
			font-size: 3rem;
		}
		@media(min-width: 1400px) {
			font-size: 4rem;
		}
	}
	
	img {
		height: 20px;
		width:  auto;
		@media(min-width: 768px) {
			height: 35px;
		}
		@media(min-width: 992px) {
			height: 45px;
		}
	}
}

//additional utilities
.bg-primary {
	background-color: $primary !important;
}

.bg-black {
	background-color: $black !important;
}

.bg-dark {
	background-color: $dark !important;
}

.bg-grey {
	background-color: $grey !important;
}

.bg-light {
	background-color: $light !important;
}

.bg-white {
	background-color: $white !important;
}

.rounded-bg-dark-padding {
	background-color: $dark;
	border-radius:    0.5rem;
	padding:          1rem;
	@media(min-width: 1200px) {
		padding:       2rem;
	}
	p:last-of-type {
		margin-bottom: 0;
	}
}