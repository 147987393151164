/* Loop templates - list */
//posts
.listing-blog {
	position:         relative;
	border:           1px solid $primary;
	background-color: $dark;
	border-radius:    0.5rem;
	padding:          1rem;
	height:           100%;
	
	&:hover {
		img {
			transform: scale(1.05);
		}
	}
	
	img {
		width:         100%;
		height:        auto;
		margin-bottom: 0.5rem;
		transition:    0.5s;
	}
	
	.entry-title {
		font-size: 1.5rem;
		
		a:hover {
			color: $white;
		}
	}
}

//services
.listing-services {
	&-item {
		color: $white;
		
		&:hover {
			color: $primary;
		}
	}
}

//locations
.listing-locations {
	text-align: center;
	/*background-color: $dark;
	padding:          1rem;
	border-radius:    0.5rem;*/
	&-city {
		position: relative;
		
		img {
			width:         100%;
			height:        auto;
			border-radius: 0.5rem;
			margin-bottom: 0.25rem;
		}
		
		.image-main {
			position: relative;
			z-index:  1;
		}
		
		.image-gold {
			position: absolute;
			top:      0;
			left:     0;
			z-index:  -1;
		}
		
		&:hover {
			.image-main {
				z-index: -1;
			}
			
			.image-gold {
				z-index: 1;
			}
		}
	}
	
	&-state {
		margin-top: 0.5rem;
		
		a:hover {
			text-decoration: underline;
		}
	}
}

//brands
#brandsTypes {
	@media(min-width: 576px) {
		.nav-item {
			width: 25%;
			
			.nav-link {
				width: 100%;
			}
		}
	}
}

#brand-service-type {
	border:        1px solid $primary;
	border-radius: 0.25rem;
	color:         $white;
	padding:       1rem 3rem;
	
	&.dropdown-toggle:after {
		margin-left: 0.5rem;
	}
}

.listing-brands {
	background-color: $dark;
	border-radius:    0.5rem;
	padding:          1rem;
	margin-top:       0.25rem;
	margin-bottom:    0.25rem;
	@media(min-width: 1200px) {
		margin-top:    0.5rem;
		margin-bottom: 0.5rem;
	}
	
	img {
		max-height: 150px;
		height:     auto;
		width:      auto;
	}
}