#main-nav {
	padding-top:    1rem;
	padding-bottom: 1rem;
	@media(min-width: 992px) {
		padding-top:    1.5rem;
		padding-bottom: 1.5rem;
	}
	
	.navbar-brand {
		padding-top:    0;
		padding-bottom: 0;
		margin:         0;
	}
	
	.navbar-toggler {
		border:     0;
		margin:     0;
		padding:    0;
		background: transparent;
		box-shadow: none !important;
		
		.bar {
			display:          block;
			width:            2.25rem;
			height:           0.1rem;
			margin:           0.33rem 0 0.33rem auto;
			transition:       all 0.3s ease-in-out;
			border-radius:    0.25rem;
			background-color: $white;
			text-align:       right;
			@media (min-width: 992px) {
				width:  2.5rem;
				height: 0.25rem;
				margin: 0.5rem 0 0.5rem auto;
			}
		}
		
		.bar-1 {
		}
		
		.bar-2 {
			width:       110%;
			margin-left: -10%;
		}
		
		.bar-3 {
			width: 75%;
		}
		
		&:hover {
			.bar-2 {
				width:       100%;
				margin-left: 0;
			}
			
			.bar-3 {
				width: 100%;
			}
		}
	}
	
	.phone-book {
		margin: 0 1rem 0 auto;
	}
	
	.nav-phone {
		vertical-align: middle;
		font-size:      1.25rem;
		line-height:    1;
		margin:         0 1rem;
		font-family:    $secondary-font;
		@media(min-width: 768px) {
			font-size: 1.5rem;
		}
		@media(min-width: 992px) {
			font-size: 2.25rem;
		}
		@media(min-width: 1400px) {
			font-size: 3rem;
		}
	}
	
	.btn {
		font-size: 1rem;
		margin:    0 1rem 0 0;
		padding:   0.25rem 1rem;
		@media(min-width: 768px) {
			font-size: 1.15rem;
			padding:   0.375rem 1.25rem;
		}
		@media(min-width: 1200px) {
			font-size:   1.5rem;
			padding:     0.625rem 2rem;
			margin-left: 0.5rem;
		}
	}
}