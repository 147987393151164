.why {
	@extend .section-paddings;
	
	.row {
		position:         relative;
		padding-top:      1rem;
		padding-bottom:   1rem;
		@media(min-width: 768px) {
			background-image: none;
			padding-top:      1.5rem;
			padding-bottom:   1.5rem;
		}
		@media(min-width: 1200px) {
			padding-top:    2rem;
			padding-bottom: 2rem;
		}
	}
	
	&-texts {
		&-para {
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
			h1,h2,h3,h4,h5,h6 {
				@extend .title;
				color: $primary;
				text-transform: uppercase;
				margin-bottom: 0.5rem;
			}
		}
		
		.btn {
			margin-top: 1rem;
		}
	}
}