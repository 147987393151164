.pagination {
	display:    inline-flex;
	margin-top: 3rem;
	
	.page-item {
		.page-link {
			background-color: $dark;
			border:           1px solid $primary;
			font-size:        1.25rem;
			font-weight:      500;
			margin:           0 0.25rem;
			border-radius:    0.5rem;
			height:           2.75rem;
			width:            2.75rem;
			
			&:focus {
				box-shadow: none;
			}
			
			&:hover {
				background-color: $black;
				color:            $white;
			}
		}
		
		&.active {
			.page-link {
				background-color: $primary;
				color:            $black;
			}
		}
	}
}