.top-nav {
	background-color: $black;
	
	&-phone {
		padding: 0;
		
		a {
			vertical-align: middle;
			font-size:      1.25rem;
			line-height:    1;
			font-family:    $secondary-font;
		}
	}
	
	&-book {
		padding: 0;
		
		.btn {
			font-size:     1rem;
			width:         100%;
			border:        0;
			border-radius: 0;
		}
	}
}