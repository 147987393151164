.contact {
	&-item {
		border-radius: 0.5rem;
		height:        100%;
		padding:       1rem;
		@media(min-width: 992px) {
			padding: 1.5rem;
		}
		@media(min-width: 1400px) {
			padding: 3rem;
		}
	}
	
	&-form {
		background-color: $dark;
		margin-top:       2rem;
		@media(min-width: 768px) {
			margin-top: 0;
		}
	}
	
	&-details {
		border: 1px solid $primary;
		
		.hours {
			color: $primary;
		}
		
		.service-states {
			font-family:   $secondary-font;
			margin-bottom: 0;
			
			span {
				color: $white;
			}
		}
	}
}