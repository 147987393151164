.accordion {
	.accordion-item {
		border:           1px solid $primary;
		margin:           1rem 0;
		background-color: $black;
		border-radius:    0.5rem;
	}
	
	.accordion-button {
		background-color: $dark;
		box-shadow:       none;
		color:            $white;
		border-radius:    0.5rem !important;
		
		&:after {
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
		}
		
		&:not(.collapsed) {
			color:         $primary;
			border-radius: 0.5rem 0.5rem 0 0 !important;
			
			&:after {
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e6b209'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
			}
		}
	}
}