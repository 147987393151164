.book {
	&-form {
		@extend .rounded-bg-dark-padding;
		border-radius: 0.5rem;
		padding:       1rem;
		@media(min-width: 992px) {
			padding: 1.5rem;
		}
		@media(min-width: 1200px) {
			padding: 2rem;
		}
		@media(min-width: 1400px) {
			padding: 3rem;
		}
	}
}