.social-share {
	.fa:after {
		height:      1.25rem;
		width:       1.25rem;
		margin-left: 0.5rem;
	}
}

.entry-content-item {
	//@extend .rounded-bg-dark-padding;
	margin-bottom: 2rem;
	@media(min-width: 1200px) {
		margin-bottom: 3rem;
	}
	
	&-wysiwyg {
		margin: 1rem auto 0;
	}
}

.entry-content-banner {
	text-align: center;
	
	img {
		margin: 0 auto 2rem;
		@media(min-width: 1200px) {
			margin: 3rem auto 4rem;
		}
	}
	
	&.mb-0 {
		img {
			margin-bottom: 0;
		}
	}
}

