.btn {
	border-radius:    0.25rem;
	border:           2px solid $primary;
	transition:       border 0.5s ease;
	padding:          0.5rem 0.75rem;
	font-size:        1.25rem;
	font-family:      $secondary-font;
	line-height:      1;
	color:            $black;
	background-color: $primary;
	@media (min-width: 768px) {
		padding: 0.75rem 1.25rem;
	}
	@media (min-width: 992px) {
		padding: 1rem 1.5rem;
	}
	@media (min-width: 1200px) {
		font-size: 1.5rem;
		padding:   1.25rem 2rem;
	}
	
	&:hover, &:active, &:focus, &:active:focus {
		background-color: lighten($primary, 10);
		border:           2px solid $black;
		box-shadow:       none !important;
	}
}